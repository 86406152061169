.cpcs-container {
  position: relative;
  padding: 20px;
  font-family: "Noto Sans", sans-serif;
  color: #2c3e50;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cpcs-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4a90e2;
  z-index: -1;
}

.cpcs-hero {
  text-align: center;
  padding: 40px 20px;
  background: transparent;
  color: #f9f9f9;
  position: relative;
}

.cpcs-hero h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.cpcs-hero p {
  font-size: 1.2em;
}

.cpcs-image-section {
  text-align: center;
  margin: 20px 0;
}

.cpcs-image-section img {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.cpcs-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  background: #e3e6f1;
  border-radius: 10px;
}

.cpcs-info > div {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: black;
}

.cpcs-info h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.cpcs-info ul {
  list-style-type: none;
  padding: 0;
}

.cpcs-info li {
  padding: 5px 0;
  font-size: 1.1em;
  color: black;
}

.cpcs-requirements {
  display: flex;
  flex-direction: column;
}

.cpcs-requirements > div {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: black;
  margin-bottom: 20px;
}

.cpcs-requirements h2,
.cpcs-requirements h3 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.cpcs-requirements p {
  font-size: 1em;
  line-height: 1.6;
  color: black;
}

.assessment-info {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  color: black;
}

.cpcs-categories {
  background: #e3e6f1;
  border-radius: 10px;
  color: black;
}

.cpcs-categories h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #4a90e2;
}

.categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.category {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px;
  min-width: 280px;
  color: black;
}

.category h3 {
  font-size: 1.3em !important;
  margin-bottom: 10px !important;
  color: #4a90e2 !important;
}

.category ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #666;
}

.category li {
  padding: 5px 0;
  font-size: 1.1em;
  color: #666;
}

@media (min-width: 1600px) {
  .cpcs-content {
    max-width: 1000px;
  }
}
